import Helmet from 'react-helmet'
import './notFound404.css'

const NotFound404 = () => {
	return (
		<div id='not-found' className='boxed'>
			<Helmet>
				<title>Institut Bellarosa | Page 404</title>
				<meta name="description" content={`Nous n'avons pas trouvé la page que vous recherchez sur le site de l'instut Bellarosa`} />
			</Helmet>
			<h1>Oups, voilà qui n'était pas prévu...</h1>
			<p>Erreur 404</p>
			<p>La page que vous recherchez semble introuvable.</p>
		</div>
	)
}

export default NotFound404
