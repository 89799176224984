import { NavLink } from 'react-router-dom'

import { BiCopyright } from 'react-icons/bi'
import { TiSocialInstagram, TiSocialFacebook } from 'react-icons/ti'

import './footer.css'

const Footer = () => {
	return (
		<div id='footer'>
			<div className='copyright'>
				<BiCopyright /> 2022 Institut Bellarosa |
				<NavLink to={"/mentions-legales"}>
					Mentions Légales
				</NavLink> |
				Réalisé par <a href='https://jeromegallego.fr/' target="_blank" rel="noreferrer">Jérôme GALLEGO</a>
			</div>
			<div className='socials'>
				<a href='https://www.instagram.com/bellarosa_joanna/?hl=fr' target="_blank" rel="noreferrer"><TiSocialInstagram className='react-icons' /></a>
				<a href='https://www.facebook.com/BellarosaJoannaMerxheim/' target="_blank" rel="noreferrer"><TiSocialFacebook className='react-icons' /></a>
			</div>
		</div>
	)
}

export default Footer
