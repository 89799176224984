import Helmet from 'react-helmet'
import './legalNotice.css'

const LegalNotice = () => {
	return (
		<div id='legal-notice' className='boxed'>
			<Helmet>
				<title>Institut Bellarosa | Mentions légales</title>
				<meta name="description" content={`Mentions légales concernant la société "L'institut Bellarosa"`} />
			</Helmet>

			<h1>Mentions Légales</h1>
			<p>Bellarosa - Entreprise Individuelle</p>
			<p>16 rue des vignes 68500 merxheim</p>
			<p>Montant capital 2000€</p>
			<p>SIREN 798 768 321</p>
			<p>SIRET 798 768 321 000 27</p>
		</div>
	)
}

export default LegalNotice
