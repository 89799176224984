import './epilations.css'

import IMG1 from '../../../assets/prestations/epilations.webp'
import IMG2 from '../../../assets/prestations/epilations_face_definitive.webp'
import IMG3 from '../../../assets/prestations/epilations_body_definitive.webp'
import PricingTable from 'components/pricingTable/PricingTable'
import LayoutPrestation from 'components/layoutPrestation/LayoutPrestation'
import Helmet from 'react-helmet'

const baseClass = 'epilations'

const Epilations = () => {
	const prices = [
		{
			"data": [
				{
					"label": "Sourcils",
					"price": "9€"
				},
				{
					"label": "Lèvres ou Menton",
					"price": "8€"
				},
				{
					"label": "Sourcils au fil",
					"price": "12€"
				},
				{
					"label": "Lèvres ou Menton au fil",
					"price": "10€"
				},
				{
					"label": "Aisselles",
					"price": "10€"
				},
				{
					"label": "Bras",
					"price": "16€"
				},
			]
		},
		{
			"data": [
				{
					"label": "Maillot simple",
					"price": "12€"
				},
				{
					"label": "Maillot échancré",
					"price": "18€"
				},
				{
					"label": "Maillot intégral",
					"price": "28€"
				},
				{
					"label": "Demi Jambes",
					"price": "21€"
				},
				{
					"label": "Jambes complètes",
					"price": "28€"
				},
			]
		}
	]

	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>Institut Bellarosa | Épilations à la cire & épilations définitive</title>
				<meta name="description" content="épilations de qualité avec des cires d'exception sans colophane, épilation définitive avec la technique INFINY, épilation révolutionnaire ALPHA SYSTEM" />
			</Helmet>

			<h1>Épilations</h1>

			<LayoutPrestation
				type={"left"}
				img={IMG3}
				imgAlt={"Institut Bellarosa - Épilations definitive corps"}
				content={[
					"ALPHA SYSTEM est le système révolutionnaire pour nous les spécialistes et professionnelles de la beauté les plus exigeants.Équipé des technologies les plus performantes au monde pour l'épilation définitive.L'alpha system vous garantit une qualité et une fiabilité sans compromis et l'exigence de sécurité la plus haute.Notre appareil permet d'éliminer 95% des poils de façon définitive.Adieu les poils sous peau... les irritations de peau... les douleurs de l'épilation... Adieu au fait simplement de penser à vos poilsNotre premier rendez-vous dure environ 1h00, afin de personnaliser votre traitement, connaître votre phototype mais aussi vous proposer un devis personnalisé. Vous découvrirez pendant ce rendez-vous notre machine grâce au flash test que nous vous proposerons."
				]}
			/>

			<LayoutPrestation
				type={"right"}
				img={IMG1}
				imgAlt={"Institut Bellarosa - Épilations cire"}
				content={[
					"Toutes nos épilations se transforment en soin de qualité grâce à nos cire d'exception sans colophane.",
					"Nous utilisons les cires pelables et cires jetables de la marque Perron rigot"
				]}
			/>

			<LayoutPrestation
				type={"left"}
				img={IMG2}
				imgAlt={"Institut Bellarosa - Épilations definitive visage"}
				content={[
					"La technique INFINY est une épilation définitive essentiellement pour le visage. Elle consiste à insérer un filament à usage unique dans l’invagination du follicule pileux, avec précision, pour provoquer une montée en température en quelques millièmes de secondes. Les technologies performantes et innovantes de INFINY vont détruire les cellules souches du bulbe et de la papille dermique responsables de la repousse des poils qui seront éliminés définitivement.Il faudra en moyenne 12 mois à 18 mois de traitement."
				]}
			/>

			<PricingTable
				title={"Tarifs"}
				content={prices}
			/>
		</div>
	)
}

export default Epilations
