import emailjs from 'emailjs-com';
import { useEffect, useRef } from 'react';

import { BsCalendarPlusFill, BsTelephone } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { SiGooglemaps } from 'react-icons/si';

import { LINK_RESERVETION } from 'constants';
import Helmet from 'react-helmet';
import './contact.css';

const Contact = () => {
	const ref = useRef(null);
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_l2ravjs', 'template_gbkckhd', form.current, '4tT2hwRC-o4YR23GY')

		e.target.reset()
	};

	useEffect(() => {
		if (ref.current) {
			const myLatLng = { lat: 47.906800, lng: 7.290910 };

			const map = new window.google.maps.Map(ref.current, {
				center: myLatLng,
				zoom: 14,
				fullscreenControl: false,
				// gestureHandling: "none",
				zoomControl: true,
			});

			new window.google.maps.Marker({
				position: myLatLng,
				map,
				animation: window.google.maps.Animation.DROP,
				title: "Institut Bellarosa",
			});
		}
	}, [ref]);

	return (
		<div id='contact'>
			<Helmet>
				<title>Épilations, soins, ongles, maquillages? Contactez l'institut Bellarosa</title>
				<meta name="description" content="Besoin d'une épilation, d'un soin du visage et/ou du corps, une manucure ou encore d'un maquillage ? N'hésitez plus, et contactez l'Institut de beauté Bellarosa" />
			</Helmet>
			<div className='contact__maps' ref={ref} />

			<div className='boxed'>
				<h1>Contact</h1>
				<div className="contact__content">
					<div className="contact__infos">
						<div className="contact__options contact__coordinates">
							<h2>Coordonnées</h2>
							<p><span className='contact__infos__label'><SiGooglemaps className='react-icons' /></span> 16 rue des vignes 68500 Merxheim</p>
							<p><span className='contact__infos__label'><BsTelephone className='react-icons' /></span> 03 89 28 58 72</p>
							<p><span className='contact__infos__label'><FiMail className='react-icons' /></span> joanna.moinaux@gmail.com</p>
						</div>
						<div className='contact__link'>
							<a href={LINK_RESERVETION} target='_blank' rel="noreferrer">
								Prendre RDV <BsCalendarPlusFill className='react-icons' />
							</a>
						</div>
						<div className="contact__options contact__shedule">
							<h2>Horaires <span>Uniquement sur rendez-vous</span></h2>

							<p><span className='contact__infos__label'>Lundi</span> 8h30 - 18h30</p>
							<p><span className='contact__infos__label'>Mardi</span> Fermé</p>
							<p><span className='contact__infos__label'>Mercredi</span> 9h00 - 18h00</p>
							<p><span className='contact__infos__label'>Jeudi</span> 9h00 - 18h00</p>
							<p><span className='contact__infos__label'>Vendredi</span> 9h00 - 18h00</p>
							<p><span className='contact__infos__label'>Samedi</span> 8h00 - 13h00</p>
						</div>
					</div>
					<div className="contact__form">
						<form ref={form} onSubmit={sendEmail}>
							<input type="text" name='name' placeholder='Nom et prénom' required />
							<input type="email" name='email' placeholder='Votre email' required />
							<textarea name="message" rows="7" placeholder='Votre message' required ></textarea>
							<button type='submit'>Envoyer</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact
