// https://www.npmjs.com/package/react-awesome-slider
// https://caferati.me/demo/react-awesome-slider/images
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';

// https://www.npmjs.com/package/react-parallax
// https://codesandbox.io/embed/r0yEkozrw?view=preview
import { Parallax } from "react-parallax";

// https://openbase.com/js/react-slick
// http://kenwheeler.github.io/slick/
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import SLIDER_1 from 'assets/slider/slider_1.webp';
import SLIDER_2 from 'assets/slider/slider_2.webp';
import SLIDER_3 from 'assets/slider/slider_3.webp';
import SLIDER_4 from 'assets/slider/slider_4.webp';

import HOME from 'assets/home.webp';

import PERSON1 from 'assets/team/person1.webp';
import PERSON2 from 'assets/team/person2.webp';
import PERSON3 from 'assets/team/person3.webp';
import PERSON4 from 'assets/team/person4.webp';

import PRESTATIONS1 from '../../assets/prestations/beaute_mains_pieds.webp';
import PRESTATIONS2 from '../../assets/prestations/epilations.webp';
import PRESTATIONS6 from '../../assets/prestations/jolies_compliments.webp';
import PRESTATIONS5 from '../../assets/prestations/maquillages.webp';
import PRESTATIONS4 from '../../assets/prestations/soins_corps.webp';
import PRESTATIONS3 from '../../assets/prestations/soins_visage.webp';

import PARTNER1 from '../../assets/partners/partner1.webp';
import PARTNER2 from '../../assets/partners/partner2.webp';
import PARTNER3 from '../../assets/partners/partner3.webp';
import PARTNER4 from '../../assets/partners/partner4.webp';
import PARTNER5 from '../../assets/partners/partner5.webp';
import PARTNER6 from '../../assets/partners/partner6.webp';
import PARTNER7 from '../../assets/partners/partner7.webp';

import { isMobile } from 'helpers/is-mobile';
import Helmet from 'react-helmet';
import './home.css';

const baseClass = 'home'

const Home = () => {
	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>L'institut Bellarosa | Salon de beauté pour prendre soins de vous !</title>
				<meta name="description" content="L'institut Bellarosa, salon de beauté et de soins, nous prenons soins de vous avec des produits de qualités. Situé à Merxheim près de Colmar et Mulhouse" />
			</Helmet>

			<AwesomeSlider cssModule={AwesomeSliderStyles} className={`awesome-slider`}>
				<div data-src={SLIDER_3} data-alt="Institut Bellarosa - Slider n°1" />
				<div data-src={SLIDER_2} data-alt="Institut Bellarosa - Slider n°2" />
				<div data-src={SLIDER_4} data-alt="Institut Bellarosa - Slider n°3" />
				<div data-src={SLIDER_1} data-alt="Institut Bellarosa - Slider n°4" />
			</AwesomeSlider>

			<div className={`${baseClass}__section`}>
				<h1>L’institut de Beauté | Bellarosa</h1>
				<div className={`${baseClass}__section__institut`}>
					<p className='boxed'>Votre institut de Beauté est uniquement sur rendez-vous. Nous sommes fermés pendant les soins afin de vous assurer un moment de bien-être. Merci de nous contacter avant de vous déplacer pour vos produits ou bons cadeaux</p>
					<Parallax
						bgImage={HOME}
						strength={200}
						renderLayer={(percentage) => (
							<div>
								<div
									className='parallax__content'
									style={{
										opacity: percentage * 1,
									}}
								>
									<p className='parallax__content__text1'>
										Après 10 ans d'expérience, j'ai voulu créé en 2020 un lieu à mon image. Un lieu où l'on prends soin de vous, où les soins sont entièrement personnalisés.
										Situé à Merxheim, nous sommes un centre où vous nous retrouverez uniquement sur rendez-vous : afin de garder la qualité de nos soins, nous suspendons l'accueil au public afin de vous préserver durant votre soin relaxant.<br /><br />
										Nous vous écoutons, nous vous conseillons et surtout nous nous adaptons à vos envies.
									</p>
									<p className='parallax__content__text2'>
										Nous avons fait le choix de travailler avec la marque Thalac pour le plus grand respect de votre peau grâce aux principes actifs marins.
										Nous avons également à coeur de réduire les déchets, c'est pour cela que nous travaillons avec des cotons lavables et nos lits sont recouverts de linge lavable également.
										Équipé de trois cabines, venez vivre un moment unique, un moment de partage et de bien-être.
									</p>
								</div>
							</div>
						)}
					>
						<div style={{ height: 500 }}>
						</div>
					</Parallax>
					<div className='parallax__content_mobile boxed'>
						<p>
							Après 10 ans d'expérience, j'ai voulu créé en 2020 un lieu à mon image. Un lieu où l'on prends soin de vous, où les soins sont entièrement personnalisés.
							Situé à Merxheim, nous sommes un centre où vous nous retrouverez uniquement sur rendez-vous : afin de garder la qualité de nos soins, nous suspendons l'accueil au public afin de vous préserver durant votre soin relaxant.<br /><br />
							Nous vous écoutons, nous vous conseillons et surtout nous nous adaptons à vos envies.
						</p>
						<p>
							Nous avons fait le choix de travailler avec la marque Thalac pour le plus grand respect de votre peau grâce aux principes actifs marins.
							Nous avons également à coeur de réduire les déchets, c'est pour cela que nous travaillons avec des cotons lavables et nos lits sont recouverts de linge lavable également.
							Équipé de trois cabines, venez vivre un moment unique, un moment de partage et de bien-être.
						</p>
					</div>
				</div>
			</div >
			<div className={`${baseClass}__section odd`}>
				<h2>Nos prestations</h2>
				<div className={`${baseClass}__section__prestations`}>
					<a href='/prestations/beaute-mains-pieds'>
						<div className={`${baseClass}__prestations__card beaute-mains-pieds`} style={{
							backgroundImage: `url(${PRESTATIONS1})`
						}}>
							<span>Beauté des Mains & des Pieds</span>
						</div>
					</a>

					<a href='/prestations/epilations'>
						<div className={`${baseClass}__prestations__card epilations`} style={{
							backgroundImage: `url(${PRESTATIONS2})`
						}}>
							<span>Épilations</span>
						</div>
					</a>

					<a href='/prestations/soins-visage'>
						<div className={`${baseClass}__prestations__card soins-visage`} style={{
							backgroundImage: `url(${PRESTATIONS3})`
						}}>
							<span>Soins du visage</span>
						</div>
					</a>
					<a href='/prestations/soins-corps'>
						<div className={`${baseClass}__prestations__card soins-corps`} style={{
							backgroundImage: `url(${PRESTATIONS4})`
						}}>
							<span>Soins du corps</span>
						</div>
					</a>

					<a href='/prestations/maquillages'>
						<div className={`${baseClass}__prestations__card maquillages`} style={{
							backgroundImage: `url(${PRESTATIONS5})`
						}}>
							<span>Maquillages</span>
						</div>
					</a>

					<a href='/prestations/jolis-compliments'>
						<div className={`${baseClass}__prestations__card jolis-compliments`} style={{
							backgroundImage: `url(${PRESTATIONS6})`
						}}>
							<span>Les jolis compliments</span>
						</div>
					</a>
				</div>
			</div>
			<div className={`${baseClass}__section`}>
				<h2>Notre équipe</h2>
				<div className={`${baseClass}__section__team boxed`}>
					<div className={`${baseClass}__team__person`}>
						<img src={PERSON1} alt="Institut Bellarosa - Employé n°1" />
						<p className={`${baseClass}__team__name`}>Joanna M.</p>
						<p className={`${baseClass}__team__libelle`}>Gérante</p>
					</div>
					<div className={`${baseClass}__team__person`}>
						<img src={PERSON2} alt="Institut Bellarosa - Employé n°2" />
						<p className={`${baseClass}__team__name`}>Camille K.</p>
						<p className={`${baseClass}__team__libelle`}>Esthéticienne</p>
					</div>
					<div className={`${baseClass}__team__person`}>
						<img src={PERSON3} alt="Institut Bellarosa - Employé n°3" />
						<p className={`${baseClass}__team__name`}>Élise</p>
						<p className={`${baseClass}__team__libelle`}>Esthéticienne</p>
					</div>
					<div className={`${baseClass}__team__person`}>
						<img src={PERSON4} alt="Institut Bellarosa - Employé n°4" />
						<p className={`${baseClass}__team__name`}>Mathilde</p>
						<p className={`${baseClass}__team__libelle`}>Apprentie esthéticienne</p>
					</div>
				</div>
			</div>
			<div className={`${baseClass}__section odd`}>
				<h2>Nos partenaires</h2>
				<div className={`${baseClass}__section__partners`}>
					<Slider
						autoplay={true}
						infinite={true}
						slidesToShow={isMobile() ? 1 : 3}
					>
						<div><img src={PARTNER1} alt="Institut Bellarosa - Partenaire n°1" /></div>
						<div><img src={PARTNER2} alt="Institut Bellarosa - Partenaire n°2" /></div>
						<div><img src={PARTNER3} alt="Institut Bellarosa - Partenaire n°3" /></div>
						<div><img src={PARTNER4} alt="Institut Bellarosa - Partenaire n°4" /></div>
						<div><img src={PARTNER5} alt="Institut Bellarosa - Partenaire n°5" /></div>
						<div><img src={PARTNER6} alt="Institut Bellarosa - Partenaire n°6" /></div>
						<div><img src={PARTNER7} alt="Institut Bellarosa - Partenaire n°7" /></div>
					</Slider>
				</div>
			</div>
		</div >
	)
}

export default Home
