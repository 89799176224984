import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HeaderDesktop from './components/header/HeaderDesktop';
import HeaderMobile from 'components/header/HeaderMobile';
import Footer from './components/footer/Footer';

import { isMobileOrTablet } from 'helpers/is-mobile';
import { routes } from 'routes';

const App = () => {
	return (
		<BrowserRouter>
			{isMobileOrTablet() ? <HeaderMobile /> : <HeaderDesktop />}

			<div className={`app-content`}>
				<Routes>
					{routes.map(route => {
						return <Route
							path={route.path}
							element={route.component}
							key={`routes_${route.path}`}
						/>
					})}
				</Routes>
			</div>

			<Footer />
		</BrowserRouter>
	)
}

export default App