import './layoutPrestation.css'

const baseClass = 'layout-prestation'

const LayoutPrestation = ({
	img,
	imgAlt,
	type,
	content
}) => {
	return (
		<div className={`${baseClass} boxed ${type === 'left' ? 'left' : 'right'}`}>
			<div className={`${baseClass}__image`}>
				<figure><img src={img} alt={imgAlt} /></figure>
			</div>
			<div className={`${baseClass}__description`}>
				{content.map((el, index) => {
					return <p key={`layout_prestation_paragraphe_${index}`} dangerouslySetInnerHTML={{ __html: el }}>
					</p>
				})}
			</div>
		</div>
	)
}

export default LayoutPrestation
