import './soinsCorps.css'

import IMG1 from '../../../assets/prestations/soins_corps.webp'
import IMG2 from '../../../assets/prestations/soins_corps_pijat.webp'
import PricingTable from 'components/pricingTable/PricingTable'
import LayoutPrestation from 'components/layoutPrestation/LayoutPrestation'
import Helmet from 'react-helmet'

const baseClass = 'soins-visage'

const SoinsCorps = () => {
	const prices = [
		{
			"title": "Les Cocooning",
			"data": [
				{
					"label": "Modelage du dos - 25min",
					"price": "35€"
				},
				{
					"label": "Modelage pieds mains tête - 35min",
					"price": "39€"
				},
				{
					"label": "Modelage cuir chevelu balinais - 45min",
					"price": "59€"
				},
				{
					"label": "Modelage Femme enceinte - 60min",
					"price": "77€"
				},
				{
					"label": "Soins aux pierres chaudes - 75min",
					"price": "85€"
				},
				{
					"label": "Modelage à 4 mains - 50min",
					"price": "100€"
				},
			]
		},
		{
			"title": "Les Spécifiques",
			"data": [
				{
					"label": "Méthode relaxinate du dos - 45min",
					"price": "55€"
				},
				{
					"label": "Réflexologie plantaire Thaï - 45min",
					"price": "59€"
				},
				{
					"label": "Stimulation cellulaire avancée",
					"price": "sur devis"
				},
			]
		},
		{
			"title": "Les soins personnalisés Bellarosa",
			"data": [
				{
					"label": "Visage ou Corps - 30min",
					"price": "45€"
				},
				{
					"label": "Visage et Corps - 60min",
					"price": "75€"
				},
				{
					"label": "Visage et Corps - 75min",
					"price": "105€"
				},
				{
					"label": "Visage et Corps - 120min",
					"price": "135€"
				},
			]
		},
	]

	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>Institut Bellarosa | Soins du corps classique & soins Pijat Kepala</title>
				<meta name="description" content="Le salon de soins et beauté Bellarosa vous propose des soins uniques et personnalisés mais aussi des massages indonésien comme le pijat kepala" />
			</Helmet>

			<h1>Soins du corps</h1>

			<LayoutPrestation
				type={"left"}
				img={IMG1}
				imgAlt={"Institut Bellarosa - Soins corps classique"}
				content={[
					"Chaque soin est un moment unique pour vous, c'est pour cela que nous avons personnalisé chacun de nos modelages.",
					"Matelas chauffant.. bougie d'ambiance. Serviette et huile chaude - Venez découvrir votre pause bien-être !"
				]}
			/>

			<LayoutPrestation
				type={"right"}
				img={IMG2}
				imgAlt={"Institut Bellarosa - Soins corps pijat kepala"}
				content={[
					"Soin Pijat Kepala : Modelage de 45min du cuir chevelu.. du haut du dos.. des bras et du cou Ce massage indonésien améliore votre circulation sanguine et dénouera tous vos nœuds. Il agit positivement en cas de fatigue, fatigue visuelle, de sinusite ou d'insomnie. Profitez de ce modelage relaxant = Byebye le stress",
				]}
			/>

			<PricingTable
				title="Tarifs"
				content={prices}
			/>
		</div>
	)
}

export default SoinsCorps
