import React from 'react'
import { NavLink } from 'react-router-dom';

import './headerDesktop.css'

import { BsTelephone, BsCalendarPlusFill } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { FaChevronDown } from 'react-icons/fa';

import LOGO from '../../assets/logo.webp'
import { LINK_RESERVETION } from 'constants';

const baseClass = "header-desktop"

const HeaderDesktop = () => {
	return (
		<header id={baseClass}>
			<div className={`${baseClass}__head`}>
				<div className={`${baseClass}__info`}>
					<div className={`${baseClass}__tel`}>
						<BsTelephone className='react-icons' /> 03 89 28 58 72
					</div>
					<div className={`${baseClass}__mail`}>
						<FiMail className='react-icons' /> joanna.moinaux@gmail.com
					</div>
				</div>
				<div className={`${baseClass}__callToAction`}>
					<a href={LINK_RESERVETION} target='_blank' rel="noreferrer">
						Prendre RDV <BsCalendarPlusFill className='react-icons' />
					</a>
				</div>
			</div>

			<div className={`${baseClass}__logo`}>
				<NavLink to={"/"}>
					<img src={LOGO} alt="Institut Bellarosa - logo" />
				</NavLink>
			</div>
			<div className={`${baseClass}__menu`}>
				<NavLink to={"/"}>
					Accueil
				</NavLink>
				<div className='dropdownlink'>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href='#' className='dropdown-button'>
						Prestations <FaChevronDown className='react-icons' />
					</a>
					<div className='dropdown-content'>
						<NavLink to={"/prestations/beaute-mains-pieds"}>
							Beauté des mains & pieds
						</NavLink>
						<NavLink to={"/prestations/epilations"}>
							Épilations
						</NavLink>
						<NavLink to={"/prestations/soins-visage"}>
							Soins du visage
						</NavLink>
						<NavLink to={"/prestations/soins-corps"}>
							Soins du corps
						</NavLink>
						<NavLink to={"/prestations/maquillages"}>
							Maquillages
						</NavLink>
						<NavLink to={"/prestations/jolis-compliments"}>
							Les jolis compliments
						</NavLink>
					</div>
				</div>

				<NavLink to={"/galerie"}>
					Galerie
				</NavLink>
				<NavLink to={"/contact"}>
					Contact
				</NavLink>
			</div>
		</header>
	)
}

export default HeaderDesktop
