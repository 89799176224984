import './joliesCompliments.css'

import IMG from '../../../assets/prestations/jolies_compliments.webp'
import PricingTable from 'components/pricingTable/PricingTable'
import LayoutPrestation from 'components/layoutPrestation/LayoutPrestation'
import Helmet from 'react-helmet'

const baseClass = 'jolies-compliments'

const JoliesCompliments = () => {
	const prices = [
		{
			"data": [
				{
					"label": "Teinture + épilation des sourcils",
					"price": "21€"
				},
				{
					"label": "Teinture cils",
					"price": "16€"
				},
			]
		},
		{
			"data": [
				{
					"label": "Réhaussement Cils (avec teinture)",
					"price": "65€"
				},
			]
		}
	]

	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>Institut Bellarosa | Teintures cils et sourcils & réhaussement de cils</title>
				<meta name="description" content="Venez découvrir chez l'institut Bellarosa des teintures de cils et sourcils ainsi que des réhaussements de cils avec teinture" />
			</Helmet>
			<h1>Les jolis compliments</h1>

			<LayoutPrestation
				type={"left"}
				img={IMG}
				imgAlt={"Institut Bellarosa - Les jolis compliments"}
				content={[
					"Nous vous proposons la teinture des cils ou de sourcils, qui consiste à teindre vos cils ou poils de couleur marron ou noir afin d'intensifier votre regard.",
					"Nous vous proposerons également le rehaussement de cils qui va donner une jolie courbure aux cils, tout en gardant un aspect naturel sans épaisseur. Cela permet d'ouvrir votre regard. La tenue est d'environ 6 semaines."
				]}
			/>

			<PricingTable
				title="Tarifs"
				content={prices}
			/>
		</div>
	)
}

export default JoliesCompliments
