import './pricingTable.css'

const baseClass = 'pricing-table'

const PricingTable = ({
	title,
	content
}) => {
	return (
		<div className={`${baseClass}__pricing boxed`}>
			<h2>{title}</h2>
			<div className={`${baseClass}__pricing-content`}>
				{content.map((el, index) => {
					return <div key={`pricing_line_${index}`}>
						{!!el.title && <h3>{el.title}</h3>}
						{el.data.map((price, indexPrice) => {
							return <p key={`price_${indexPrice}`}>
								<span className={`${baseClass}__pricing__label`}>{price.label}</span> {price.price}
							</p>
						})}
					</div>
				})}
			</div>
		</div>
	)
}

export default PricingTable
