import { useState } from 'react'
import './headerMobile.css'

import {
	AiOutlineMenu,
	AiOutlineCloseCircle
} from 'react-icons/ai'

import {
	BsCalendarPlusFill
} from 'react-icons/bs'

import LOGO from '../../assets/logo.webp'
import { NavLink } from 'react-router-dom'
import { LINK_RESERVETION } from 'constants'

const baseClass = "header-mobile"

const HeaderMobile = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleMenuMobile = () => {
		setIsOpen(!isOpen)
	}

	return (
		<header id={baseClass}>
			<div id="logo">
				<img src={LOGO} alt="Institut Bellarosa - logo" />
			</div>

			<AiOutlineMenu className={`${baseClass}__icon-open-menu`} onClick={handleToggleMenuMobile} />

			{isOpen && <div className='menu_mobile_panel'>
				<AiOutlineCloseCircle className={`${baseClass}__icon-close-menu`} onClick={handleToggleMenuMobile} />
				<div id="links">
					<NavLink to={"/"} onClick={handleToggleMenuMobile}>
						Accueil
					</NavLink>
					<div className='dropdownlink'>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a href='#' className='dropdown-button'>
							Prestations
						</a>
						<div className='dropdown-content'>
							<NavLink to={"/prestations/beaute-mains-pieds"} onClick={handleToggleMenuMobile}>
								Beauté des mains & pieds
							</NavLink>
							<NavLink to={"/prestations/epilations"} onClick={handleToggleMenuMobile}>
								Épilations
							</NavLink>
							<NavLink to={"/prestations/soins-visage"} onClick={handleToggleMenuMobile}>
								Soins du visage
							</NavLink>
							<NavLink to={"/prestations/soins-corps"} onClick={handleToggleMenuMobile}>
								Soins du corps
							</NavLink>
							<NavLink to={"/prestations/maquillages"} onClick={handleToggleMenuMobile}>
								Maquillages
							</NavLink>
							<NavLink to={"/prestations/jolis-compliments"} onClick={handleToggleMenuMobile}>
								Les jolis compliments
							</NavLink>
						</div>
					</div>

					<NavLink to={"/galerie"} onClick={handleToggleMenuMobile}>
						Galerie
					</NavLink>
					<NavLink to={"/contact"} onClick={handleToggleMenuMobile}>
						Contact
					</NavLink>

					<div className={`${baseClass}__callToAction`}>
						<a href={LINK_RESERVETION} target='_blank' rel="noreferrer">
							Prendre RDV <BsCalendarPlusFill className='react-icons' />
						</a>
					</div>
				</div>
			</div>}
		</header>
	)
}

export default HeaderMobile
