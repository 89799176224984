import './maquillages.css'

import IMG from '../../../assets/prestations/maquillages.webp'
import PricingTable from 'components/pricingTable/PricingTable'
import LayoutPrestation from 'components/layoutPrestation/LayoutPrestation'
import Helmet from 'react-helmet'

const baseClass = 'maquillages'

const Maquillages = () => {
	const prices = [
		{
			"data": [
				{
					"label": "Je suis irrésistible ce soir",
					"price": "30€"
				},
				{
					"label": "Je suis la plus belle pour ce jour (avec un essai)",
					"price": "75€"
				},
			]
		}
	]

	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>Institut Bellarosa | Venez vous embellir grâce à nos coups de pinceaux</title>
				<meta name="description" content="L'institut Bellarosa vous propose plusieurs type de maquillages pour vos évènements exceptionnelle comme par exemple un mariage avec des produits naturels !" />
			</Helmet>
			<h1>Maquillages</h1>

			<LayoutPrestation
				type={"right"}
				img={IMG}
				imgAlt={"Institut Bellarosa - Maquillages"}
				content={[
					"Vous vous préparez pour le plus beau jour de votre vie ou vous participez à un événement ? Venez vous embellir grâce à nos coups de pinceaux.",
					"Nous vous écoutons, conseillons en fonction de vos envies. Du naturel au sophistiqué nous utilisons des produits naturels"
				]}
			/>

			<PricingTable
				title="Tarifs"
				content={prices}
			/>
		</div>
	)
}

export default Maquillages
