import './beauteMainsPieds.css'

import IMG from '../../../assets/prestations/beaute_mains_pieds.webp'
import PricingTable from 'components/pricingTable/PricingTable'
import LayoutPrestation from 'components/layoutPrestation/LayoutPrestation'
import Helmet from 'react-helmet'

const baseClass = "beaute-mains-pieds"

const BeauteMainsPieds = () => {
	const prices = [
		{
			"data": [
				{
					"label": "Manucure Simple",
					"price": "17€"
				},
				{
					"label": "Calluspeeling",
					"price": "39€"
				},
				{
					"label": "Gel remplissage",
					"price": "43€"
				},
				{
					"label": "Gel avec rallongement",
					"price": "53€"
				},
			]
		},
		{
			"data": [
				{
					"label": "Semi-permanent Mains ou Pieds",
					"price": "33€"
				},
				{
					"label": "Dépose + soin",
					"price": "15€"
				},
			]
		}
	]

	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>Institut Bellarosa | manucure simple, chablons et calluspeeling</title>
				<meta name="description" content="De la manucure simple au rallongement des ongles (chablons) en passant par le calluspeeling, venez embellir vos mains grâce à nos différentes prestations." />
			</Helmet>

			<h1>Beauté des Mains & des Pieds</h1>

			<LayoutPrestation
				type={"right"}
				img={IMG}
				imgAlt={"Institut Bellarosa - Beaute des mains et des pieds"}
				content={[
					"De la manucure simple au rallongement des ongles (chablons) en passant par le calluspeeling, venez embellir vos mains grâce à nos différentes prestations.",
					"Nous vous proposons des solutions adaptées à chacun(e) : ",
					"<ul><li>○ Retrouvez des mains hydratées et soignées grâce à notre manucure.</li><li>○ Qui ne rêve pas de pieds de bébé?? Venez soulager vos pieds grâce à notre soin anti callosités : Soulagés, doux, neufs : vos pieds retrouveront leur jeunesse</li><li>○ Marre que votre vernis ne tienne pas? Venez découvrir nos couleurs, on se fera un plaisir de vous faire découvrir nos 150 couleurs</li><li>○ Vous n'aimez pas la forme de vos ongles? Pas de panique, nous transformons vos ongles à vos envies. La technique du gel est de trois semaines minimum.</li></ul>",
				]}
			/>

			<PricingTable
				title="Tarifs"
				content={prices}
			/>
		</div>
	)
}

export default BeauteMainsPieds
