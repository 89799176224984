import Helmet from 'react-helmet'
import InstagramFeed from '../../components/react-ig-feed'
import '../../components/react-ig-feed/styles.module.css'

import './gallery.css'

// https://www.npmjs.com/package/react-ig-feed
// https://mohammedraji.github.io/posts/get-instagram-graph-API/

const Gallery = () => {
	// const ref = useRef(null);

	// useEffect(() => {
	// 	if (ref.current) {
	// 		window.planity = {
	// 			key: '-NopnyGnvmqO0EWOHbKh',
	// 			primaryColor: 'red',
	// 			// choisissez une des options ci-dessous
	// 			container: ref.current,
	// 			// accountContainer: container,
	// 			// appointmentContainer: container,
	// 			// giftVoucherContainer: container,
	// 			// onlineShopContainer: container
	// 		};
	// 	}
	// }, [ref]);

	return (
		<>
			{/* <div ref={ref}></div> */}
			<div id='gallery'>
				<Helmet>
					<title>Toutes les réalisations et créations de l'institut de beauté Bellarosa</title>
					<meta name="description" content="Retrouvez toutes les réalisations et créations de l'institut de beauté Bellarosa situé à Merxheim dans le haut-rhin près de Colmar et Mulhouse" />
				</Helmet>
				<h1>Galerie</h1>
				<InstagramFeed token="IGQWRPRklIMnJTcGlvSHhpVkdEcmJqREktU0FNcUhqeE9ZAdjBVZAlJHM2dLdUpabEZANR0hXZAHZAyM1V1YVJVM3pvU29OdDNnVWZArVmNYX1JxRHN5azBlcW12eEtIVkVTQVUtZAFZAQNzk0WmhyNGk2RkJCRjNfR2QzeG8ZD" />
			</div>
		</>
	)
}

export default Gallery
