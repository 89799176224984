import Home from './pages/home/Home';
import BeauteMainsPieds from './pages/prestations/beauteMainsPieds/BeauteMainsPieds';
import Epilations from './pages/prestations/epilations/Epilations';
import SoinsVisage from './pages/prestations/soinsVisage/SoinsVisage';
import SoinsCorps from './pages/prestations/soinsCorps/SoinsCorps';
import Maquillages from './pages/prestations/maquillages/Maquillages';
import JoliesCompliments from './pages/prestations/joliesCompliments/JoliesCompliments';
import Gallery from './pages/gallery/Gallery';
import Contact from './pages/contact/Contact';
import LegalNotice from './pages/legalNotice/LegalNotice';
import NotFound404 from 'pages/NotFound404/NotFound404';

export const routes = [
    { path: '/', component: <Home />, name: 'accueil' },
    { path: '/prestations/beaute-mains-pieds', component: <BeauteMainsPieds />, name: 'beauté des mains et des pieds' },
    { path: '/prestations/epilations', component: <Epilations />, name: 'épilations' },
    { path: '/prestations/soins-visage', component: <SoinsVisage />, name: 'soins du visage' },
    { path: '/prestations/soins-corps', component: <SoinsCorps />, name: 'soins du corps' },
    { path: '/prestations/maquillages', component: <Maquillages />, name: 'maquillages' },
    { path: '/prestations/jolis-compliments', component: <JoliesCompliments />, name: 'jolies compliments' },
    { path: '/galerie', component: <Gallery />, name: 'galerie' },
    { path: '/contact', component: <Contact />, name: 'contact' },
    { path: '/mentions-legales', component: <LegalNotice />, name: 'mentions legales' },
    { path: '*', component: <NotFound404 />, name: 'not found 404' },
]