import './soinsVisage.css'

import IMG from '../../../assets/prestations/soins_visage.webp'
import PricingTable from 'components/pricingTable/PricingTable'
import LayoutPrestation from 'components/layoutPrestation/LayoutPrestation'
import Helmet from 'react-helmet'

const baseClass = 'soins-visage'

const SoinsVisage = () => {
	const prices = [
		{
			"title": "L'Express - 30min",
			"data": [
				{
					"label": "Anti-Age Caviar - Revitalise et régènère",
					"price": "35€"
				}
			]
		},
		{
			"title": "Le Classique - 60min",
			"data": [
				{
					"label": "Purifiant - Rééquilibre les imperfections",
					"price": "67€"
				}
			]
		},
		{
			"title": "La Vie en Rose - 60min",
			"data": [
				{
					"label": "Libère les points de tension, Évacue le stress",
					"price": "69€"
				},
			]
		},
		{
			"title": "L'Hydra-pro - 75min",
			"data": [
				{
					"label": "Repulpe, soin 1ères rides",
					"price": "77€"
				},
			]
		},
		{
			"title": "L'Absolue - 75min",
			"data": [
				{
					"label": "Soin holistique anti-âge",
					"price": "85€"
				},
			]
		},
		{
			"title": "le Soin d'Exception - 75min",
			"data": [
				{
					"label": "Stimulation Cellulaire avancée",
					"price": "150€"
				},
			]
		},
	]

	return (
		<div className={`${baseClass}`}>
			<Helmet>
				<title>Institut Bellarosa | Soins du visage avec des produits bio et Français</title>
				<meta name="description" content="Le salon de soins Bellarosa vous propose des soins du visage avec des produits bio et d'origine France de la marque Thalac développés par des kinésithérapeutes" />
			</Helmet>

			<h1>Soins du visage</h1>

			<LayoutPrestation
				type={"left"}
				img={IMG}
				imgAlt={"Institut Bellarosa - Soins visage"}
				content={[
					"Pour vos soins visage, nous travaillons avec la marque Thalac et Thalac Bio : Nos cosmétiques sont conçus et fabriqués sur les sites de production français (Sud Lyon et Alpes de Haute Provence).",
					"Alliant les techniques manuelles à l’efficacité des actifs marins, nous avons des protocoles exclusifs. Ils sont développés par des kinésithérapeutes et sont spécifiquement adaptés à chaque étape du soin, et aux produits Thalac utilisés en cabine.",
					"Complètement personnalisé, venez mettre en valeur votre beauté naturelle. Nos esthéticiennes expertes vous aideront à découvrir ou re-découvrir votre peau tout en vous relaxant. "
				]}
			/>

			<PricingTable
				title="Tarifs"
				content={prices}
			/>
		</div>
	)
}

export default SoinsVisage
